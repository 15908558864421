//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { queryHotelOrderDetails } from "@/lib/data-service/hotel";
import { STATUS } from "@/lib/deprecated/utils/status";
const SUCCESS_CODE = STATUS.success;
export default {
  name: "hotelOrder",
  data() {
    return {
      formData: {},
      home: [],
      enterDay: [],
      orderDetail: {},
      orderPersonList: []
    };
  },
  activated() {
    this.getList();
    console.log(this.$route.query.type);
  },
  watch: {},
  methods: {
    getList() {
      let data = {
        orderNo: this.$route.query.orderNo
      };
      queryHotelOrderDetails(data).then(res => {
        if (res.code === SUCCESS_CODE) {
          console.log(res);
          this.orderDetail = res.orderDetailsResult;
          this.orderPersonList = res.orderPersonList;
          this.enterDay = [
            res.orderDetailsResult.checkInDate,
            res.orderDetailsResult.checkOutDate
          ];
        }
      });
    }
  }
};
